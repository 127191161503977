/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './static/css/bootstrap.css'
import './static/css/all.css' // Font-Awesome-Icons-CSS
import './static/css/fonts.css'  // Web Fonts
import './static/css/style.css'  // web page style
import './static/css/responsive.css'  // for mobile screen


